<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Paso 1 de 5
            </template>
            <template v-slot:subtitle>
                Información de Pago
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12">
                        <h4>Factura a Pagar</h4>
						<DataTable :value="factura" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
                            <Column header="Fecha">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
							<Column header="Descripción">	
								<template #body="{data}">
									{{data.nombre}}
								</template>
							</Column>
							<Column header="Monto">	
								<template #body="{data}">
                                    <strong v-if="moneda==1">Ref.</strong>
                                    <img :src="'images/moneda'+moneda+'.png'" width="15" v-if="moneda"/>
                                    {{data.monto}}
								</template>
							</Column>
							<Column header="Monto Bs">	
								<template #body="{data}">
                                    Bs. {{new Intl.NumberFormat("de-DE").format(((parseFloat(data.monto))*tasa).toFixed(2))}}
								</template>
							</Column>
						</DataTable>                   
					</div>
					<div class="p-col-4" v-if="factura.length>0"></div>
					<div class="p-col-3" v-if="factura.length>0"><h4><strong>Monto a Pagar:</strong></h4></div>
					<div class="p-col-3" v-if="factura.length>0"><h4><strong>
                        Bs. {{new Intl.NumberFormat("de-DE").format((factura_montobs).toFixed(2))}}
                    </strong></h4></div>
					<div class="p-col-2" v-if="factura.length>0"><h4>
                        <strong v-if="moneda==1">Ref.</strong>
                        <img :src="'images/moneda'+moneda+'.png'" width="15" v-if="moneda"/>
                        <strong>{{factura_monto.toFixed(2)}}</strong>
                        </h4></div>
                    <div class="p-field p-col-12 p-md-12" v-if="factura.length>0">
						<Button label="Agregar Pago" icon="pi pi-money-bill" class="p-button-info p-mr-2 p-mb-2" @click="CargarPago()" style="width: 200px;"/>
                        <small v-show="validationErrors.pagos && submitted" style="color: red;">Debe reportar el pago</small>
						<DataTable :value="pagos" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
							<Column header="Fecha">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
							<Column header="Tipo">	
								<template #body="{data}">
									{{data.tipo.nombre}}
								</template>
							</Column>
                            <Column header="Banco">	
                                <template #body="{data}">
                                    {{data.banco_orig.nombre}}
                                    <div v-if="data.banco">
                                        <br>{{data.banco.nombre}}
                                    </div>  
                                    <div v-if="data.movil">
                                        <br>{{data.banco.nombre}}
                                    </div>    
                                </template>
                            </Column>
							<Column header="N° de referencia bancaria">	
								<template #body="{data}">
									{{data.referencia}}<br>
                                    <img style="width: 100px;" :src="url+'pagos/'+data.img+'.jpg'" v-if="data.img"/>
								</template>
							</Column>
							<Column header="Monto Bs">	
								<template #body="{data}">
                                    Bs. {{new Intl.NumberFormat("de-DE").format((data.montobs).toFixed(2))}}
                                </template>
							</Column>
							<Column header="Monto">	
								<template #body="{data}">
                                    <strong v-if="moneda==1">Ref.</strong>
                                    <img :src="'images/moneda'+moneda+'.png'" width="15" v-if="moneda"/>
                                    {{(data.monto).toFixed(2)}}
								</template>
							</Column>
                            <Column header="Eliminar">
								<template #body="{data}">
                                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="EliminarPago(data)"/>
                                </template>
                            </Column>
						</DataTable>                   
					</div>
					<div class="p-col-3" v-if="pagos.length>0"></div>
					<div class="p-col-3" v-if="pagos.length>0"><h4><strong>Total de Pagos:</strong></h4></div>
					<div class="p-col-3" v-if="pagos.length>0"><h4><strong>
                        Bs. {{new Intl.NumberFormat("de-DE").format((pago_montobs).toFixed(2))}}
                    </strong></h4></div>
					<div class="p-col-3" v-if="pagos.length>0"><h4>
                        <strong v-if="moneda==1">Ref.</strong>
                        <img :src="'images/moneda'+moneda+'.png'" width="15" v-if="moneda"/>
                        <strong>{{pago_monto.toFixed(2)}}
                    </strong></h4>
                    </div>
					<div class="p-col-6" v-if="pagos.length>0"></div>
					<div class="p-col-3" v-if="pagos.length>0"><h4><strong>Balance:</strong></h4></div>
					<div class="p-col-3" v-if="pagos.length>0"><h4>
                        <strong v-if="moneda==1">Ref.</strong>
                        <img :src="'images/moneda'+moneda+'.png'" width="15" v-if="moneda"/>
                        <strong> {{(pago_monto-factura_monto).toFixed(2)}}
                    </strong></h4>                    
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <!--<i></i>-->
                    <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button label="Siguiente" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>

		<Dialog v-model:visible="Dialog" :style="{width: size}" header="Agregar Pago" :modal="true" class="p-fluid" :maximizable="true">
            <br>
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-8">
						<label>Tipo</label>
                            <div v-for="(stats, index) in tipos" :key="index">
                                <RadioButton id="tipo" name="subcateg" :value="stats.code" v-model="pago.tipo"/>
                                <strong> {{stats.nombre}}<br><br></strong>
                            </div>
					</div>                    
					<div class="p-col-4" v-if="pago.tipo">
                        <label>Fecha</label>
                        <InputText v-model="pago.fecha" type="date" :max="$store.state.fechaini"/>
					</div>
					<div class="p-col-7" v-if="pago.tipo">
                        <strong v-if="pago.tipo==2">{{banco_destino.nombre}}</strong>
						<label v-if="pago.tipo==3 || pago.tipo==4">Banco de Origen</label>
						<Dropdown v-model="pago.banco_orig" :options="bancos" optionLabel="nombre" placeholder="Seleccione..." v-if="pago.tipo==3 || pago.tipo==4"/>
					</div>
                    <div class="p-col-5" v-if="pago.tipo==4">
                        <label>N° de celular de origen</label>
                        <InputText v-model="pago.movil" type="number" placeholder="Número" min="0"/>
					</div>
					<div class="p-col-7" v-if="pago.tipo">
                        <label>N° de referencia bancaria</label>
                        <InputText v-model="pago.referencia" type="number" placeholder="Número" min="0"/>
					</div>
					<div class="p-col-5" v-if="pago.tipo">
                        <label>Monto Bs</label>
                        <InputNumber v-model="pago.montobs" required="true" min="1" :max="$store.state.tope"  :minFractionDigits="2"/>
					</div>
                    <div class="p-field p-col-12 p-md-4" v-if="pago.tipo">
                        <FileUpload chooseLabel="Subir Foto JPG" uploadLabel="Cargar" mode="basic" :name="token" :url="url+'pagos.php'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader(token)" :auto="true"/>
                        <small class="p-error">tamaño máximo: 1MB</small>
                    </div>
					<div class="p-field p-col-4 p-md-4" v-if="pago.tipo">
						<img style="width: 100px;" :src="url+'pagos/'+pago.img+'.jpg'" v-if="pago.img"/>
					</div>               
				</div>
			</div>
			<template #footer>
				<Button label="Agregar" icon="pi pi-check" class="p-button-secondary" @click="Agregar()" />
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
			</template>
		</Dialog>

    </div>
</template>

<script>
import API from "@/service/API";
import Token from "uuid-token-generator";
const Consulta = new API('Preinscripcion','Procesar');

export default {
    data () {
        return {
            submitted: false,
            validationErrors: {},
			formapagos: [
				{code: 1, nombre: 'Pago al Contado' , abrv: 'Contado'},
				{code: 2, nombre: 'Pago a Crédito', abrv: 'Crédito'}
			],
            formapago: null,
            bancos: [],
            banco_destino: {code: 3, nombre: 'Banco Mercantil'},
            tipos: [],
            pagos: [],
            moneda: null,
            factura: [],
            factura_monto: 0,
            factura_montobs: 0,
            pago_montobs: 0,
            pago_monto: 0,
            Dialog: false,
            pago: {
                fecha: null,
                tipo: null,
                banco_orig: null,
                banco: 3,
                referencia: null,
                tasa: null,
                moneda: null,
                monto: null,
                montobs: null,
                img: null,
                movil: 0,
            },
			size: '40vw',
			token: null,
            periodo: null,
            tasa: 1,
        }
    },
	created() {
        this.url = process.env.VUE_APP_API;
        if (this.$store.state.mobile){this.size = '90vw';}
        this.Mostrar();
	},
    methods: {
        Mostrar() {
            this.factura = [];
            this.factura_monto = 0;
            this.bancos_destino = [];
            Consulta.Procesar('Facturacion',{
            }).then(response => {
                //this.$store.state.error =response.result;
                this.factura = response.result.factura;
                this.bancos = response.result.bancos;
                this.tipos = response.result.tipos;
                this.$store.commit('ValorPetro');
                this.periodo = response.result.periodo;
                this.moneda = response.result.periodo.moneda;
                if(response.result.periodo.moneda==1){this.tasa = this.$store.state.dolar;}
                if(response.result.periodo.moneda==2){this.tasa = this.$store.state.petro;}
                response.result.factura.forEach(element => {
                    this.factura_monto = parseFloat(this.factura_monto) + parseFloat(element.monto);
                    this.factura_montobs = parseFloat(this.factura_monto)*this.tasa;
                }); 
                this.$store.commit('Loading');
            });
        },
        Validacion2() {
            if (this.pago.tipo.code==3){
                this.pago.banco = {
                    nombre: 'Banco Mercantil', 
                    code: 3
                };
            } else {this.pago.banco=null;}
        },
        CargarPago() {
            const tokgen = new Token();
            this.token = tokgen.generate();
            this.pago = {
                fecha: this.$store.state.fechaini,
                tipo: null,
                banco: 3,
                banco_orig: null,
                referencia: null,
                montobs: null,
                tasa: null,
                monto: null,
                moneda: this.moneda,
                img: null,
                movil: 0,
            };
            this.Dialog = true;
        },
		myUploader(img) {
			Consulta.Procesar('ImgPagos',{
				img: img,
			}).then(response => {
                if(response.result){
                    this.pago.img = this.token;
                }
                const tokgen = new Token();
                this.token = tokgen.generate();

				this.$store.commit('Loading');
			});
        },
		EliminarPago(selec) {
            this.pago_montobs = 0;
            this.pago_monto = 0;
			this.pagos = this.pagos.filter(val => val !== selec);
            this.pagos.forEach(element => {
                this.pago_montobs = parseFloat(this.pago_montobs) + parseFloat(element.montobs);
                this.pago_monto = parseFloat(this.pago_monto) + parseFloat(element.monto);
            }); 
		},
        Agregar(){
            if (this.pago.tipo == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el tipo de pago', life: 5000});
			} else if (this.pago.fecha == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Indique la fecha del pago', life: 10000});
			} else if (this.pago.banco_orig == null && this.pago.tipo == 3) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el banco', life: 5000});
            } else if (this.pago.movil == 0 && this.pago.tipo == 4) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduz el número de celular de origen', life: 5000});
            } else if (this.pago.referencia == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el número de referencia bancaria', life: 5000});
			} else if (this.pago.montobs == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el monto del pago', life: 5000});
			} else if (this.pago.img == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe cargar la imagen de la transferencia', life: 5000});
            } else {
                this.$store.commit('Loading');
                this.pago.banco = this.banco_destino;
                if (this.pago.tipo==2){
                    this.pago.banco_orig = this.banco_destino;
                }
				this.tipos.forEach(element => {
					if(element.code == this.pago.tipo){
						this.pago.tipo = element;
					}
				});
                Consulta.Procesar('VerifPago',{
                    pago: this.pago,
                    moneda: this.moneda,
                }).then(response => {
                    //this.$store.state.error =response;
                    if (response.result.verificacion && response.result.tasa>0) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
                        this.pago.tasa = response.result.tasa;
                        //this.pago.monto = parseFloat(this.pago.montobs) / parseFloat(this.pago.tasa);
                        this.pago.monto = Math.round((parseFloat(this.pago.montobs) / parseFloat(this.pago.tasa)) * 100) / 100;
                        this.pagos.push(this.pago);
                        this.pago_montobs = parseFloat(this.pago_montobs) + parseFloat(this.pago.montobs);
                        this.pago_monto = parseFloat(this.pago_monto) + parseFloat(this.pago.monto);
                        this.Dialog = false;
                    } else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: response.result.mens, 
							life: 3000
						});
                        this.Dialog = false;
                    }
                    this.$store.commit('Loading');
                });
            }
        },
        prevPage() {
            this.$router.push({ path: '/preinscripcion'});
        },
        nextPage() {
            this.submitted = true;
            if (this.validateForm()) {
                this.$emit('next-page', {formData: {factura: this.factura,pagos: this.pagos, factura_monto: this.factura_monto, factura_montobs: this.factura_montobs,
                    pago_montobs: this.pago_montobs, moneda: this.moneda, periodo: this.periodo, tasa: this.tasa}, pageIndex: 0});
            }
        },
        validateForm() {
            if (parseFloat(this.pago_monto) < parseFloat(this.factura_monto)) 
                this.validationErrors['pago'] = true;
            else
                delete this.validationErrors['pago'];
            if (this.pagos.length==0)
                this.validationErrors['pagos'] = true;
            else
                delete this.validationErrors['pagos'];

            return !Object.keys(this.validationErrors).length;
        },
    }
}
</script>